.card-game {
    position: relative;
    margin: 0 0 30px 0;
    background-color: #f2f2f2;
    border-radius: 10px;
}

.card-game__image, .card-game__image--small {
    height: 180px;
    width: 100%;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}

.card-game__wrapper {
    padding: 15px;
}

.card-game__title {
    font-size: 14px;
    color: #000;
    font-weight: 600;
    line-height: 1.2;
}

.card-game__release {
    font-size: 12px;
    color: #606770;
    font-weight: 400;
    margin: 5px 0 0 0;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
    .card-game__image {
        height: 200px;
    }
    .card-game__image--small {
        height: 160px;
    }
}