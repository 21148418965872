.group {
    margin: 0 0 30px 0;
    padding: 0 0 30px 0;
    border-bottom: 1px solid rgb(227, 227, 233);
}

.group__title-icon-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.group__icon {
    height: 24px;
    max-width: 100%;
    margin: 0 15px 20px 0;
}

.group__title {
    font-size: 24px;
    color: #000;
    font-weight: 600;
    line-height: 1.2;
    margin: 0 0 15px 0;
}

.group__description {
    font-size: 14px;
    color: #606770;
    font-weight: 400;
    line-height: 1.5;
}

.group__children p {
    font-size: 14px;
    color: #606770;
    font-weight: 400;
    line-height: 1.5;
}

.group__children iframe {
    height: 315px;
    max-width: 560px;
    border-radius: 10px;
}

.group__children img {
    height: 200px;
    max-width: auto;
    border-radius: 10px;
}