.loader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    background-image: url('./assets/logo.svg');
    background-repeat: no-repeat;
    background-size: auto 25px;
    background-position: center;
    visibility: hidden;
    animation: loader-animation 2s linear;
}

.progress-bar-loader {
    position: fixed;
    bottom: 0;
    height: 4px;
    /*  */
    background-color: #0070d0;
    /*  */
    animation: progress-bar-animation 1s linear;
}

@keyframes loader-animation {
    0% {
        opacity: 1;
        display: block;
        z-index: 9999;
        visibility: unset;
    }
    60% {
        opacity: 1;
        display: block;
        z-index: 9999;
        visibility: unset;
    }
    75% {
        opacity: 0;
        z-index: 9999;
    }
    100% {
        opacity: 0;
        display: none;
        z-index: unset;
    }
}

@keyframes progress-bar-animation {
    from {
        width: 0%;
    }
    to {
        width: 100%;
    }
}

@media screen and (min-width: 0px) and (max-width: 992px) {
    .loader {
        background-size: auto 20px;
    }
}