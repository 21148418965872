.card-slider {
    min-height: 400px;
    margin: 0 0 30px 0;
    padding: 60px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #e3e3e9;
    border-radius: 20px;
}

.card-slider__title {
    font-size: 24px;
    color: #000;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    margin: 0 0 15px 0;
    /*  */
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-slider__description {
    font-size: 14px;
    color: #606770;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    margin: 0 0 15px 0;
    /*  */
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-slider__button {
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    background-color: #0070d0;
    padding: 15px 30px;
    border: none;
    border-radius: 10px;
    outline: none;
    box-shadow: none;
    cursor: pointer;
}