.button-load-more {
    width: 100%;
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    background-color: #0070d0;
    padding: 15px 30px;
    margin: 0 0 30px 0;
    border: none;
    border-radius: 10px;
    outline: none;
    box-shadow: none;
    cursor: pointer;
}