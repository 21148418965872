@font-face {
    font-family: 'ArtegraSans';
    src: url('../fonts/ArtegraSans/ArtegraSans-SemiBold.otf');
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: 'ArtegraSans';
    src: url('../fonts/ArtegraSans/ArtegraSans-Regular.otf');
    font-style: normal;
    font-weight: 400;
}

*, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'ArtegraSans', sans-serif;
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

img, svg {
    vertical-align: middle;
}

.display-block-sm {
    display: none;
}

.display-block-lg {
    display: block;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
    .display-block-sm {
        display: block;
    }
    .display-block-lg {
        display: none;
    }
    .flex-direction-column-reverse-sm {
        flex-direction: column-reverse;
    }
}

/* 

inline


    display: flex;
    flex-flow: row wrap;
    align-items: center;
    
    
    */