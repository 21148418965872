.navbar-lg {
    position: fixed;
    top: 0;
    z-index: 1021;
    height: 60px;
    width: 100%;
    background-color: #fff;
    box-shadow: 2px 2px 4px 0 rgb(0 0 0 / 10%);
}

.navbar-lg__wrapper {
    height: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.navbar-lg__image {
    height: 18px;
    max-width: 100%;
    cursor: pointer;
}

.navbar-lg__link {
    font-size: 14px;
    color: #606770;
    font-weight: 600;
    text-decoration: none;
    margin: 0 15px 0 15px;
}

.navbar-lg__link:last-of-type {
    margin: 0 0 0 15px;
}

.navbar-close-sm {
    position: fixed;
    top: 0;
    z-index: 1021;
    height: 60px;
    width: 100%;
    background-color: #fff;
    display: none;
}

.navbar-close-sm__wrapper {
    height: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.navbar-close-sm__image {
    height: 18px;
    max-width: 100%;
    cursor: pointer;
}

.navbar-close-sm__menu {
    height: 25px;
    max-width: 100%;
    cursor: pointer;
}

.navbar-open-sm {
    padding: 60px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.navbar-open-sm__close {
    position: absolute;
    top: 20px;
    right: 15px;
    height: 22px;
    max-width: 100%;
    cursor: pointer;
}

.navbar-open-lg__link {
    width: 100%;
    font-size: 14px;
    color: #606770;
    font-weight: 600;
    text-decoration: none;
    margin: 0 0 15px 0;
    padding: 0 0 15px 0;
    border-bottom: 1px solid #e3e3e9;
}

.navbar-lg__language-wrapper {
    margin: 0 0 0 15px;
    padding: 0 0 0 15px;
    border-left: 1px solid #e3e3e9;
    display: inline;
}

.navbar-lg__language-button {
    font-size: 14px;
    color: #0070d0;
    font-weight: 600;
    background-color: transparent;
    padding: 5px 15px;
    border: 1px solid #0070d0;
    border-radius: 10px;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    transition: color .15s linear, background-color .15s linear, border 1s linear;
}

.navbar-lg__language-button:hover {
    color: #fff;
    background-color: #0070d0;
    border: 1px solid #0070d0;
}

.navbar-open-sm__language {
    position: absolute;
    right: 0;
    bottom: 60px;
    left: 0;
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.navbar-open-sm__language-button {
    font-size: 14px;
    color: #0070d0;
    font-weight: 600;
    background-color: transparent;
    padding: 10px 20px;
    border: 1px solid #0070d0;
    border-radius: 10px;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    transition: color .15s linear, background-color .15s linear, border 1s linear;
}

.navbar-open-sm__language-button:hover {
    color: #fff;
    background-color: #0070d0;
    border: 1px solid #0070d0;
}

/* sub-navigation */

.sub-navbar {
    display: none;
    height: 40px;
    width: 100%;
    background-color: #0070d0;
    position: fixed;
    top: 60px;
    z-index: 1021;
    box-shadow: 2px 2px 4px 0 rgb(0 0 0 / 10%);
}

.sub-navbar__wrapper {
    height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.sub-navbar__wrapper::-webkit-scrollbar {
    display: none;
}

.sub-navbar__link {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    text-decoration: none;
    border-right: 1px solid hsla(0, 0%, 100%, .2);
    margin: 0 15px -2px 0;
    padding: 0 15px 0 0;
    white-space: nowrap;
    cursor: pointer;
}

.sub-navbar__link:last-of-type {
    border-right: unset;
    margin: 0 0 -2px 0;
    padding: 0 0 0 0;
}

.sub-navbar__link-back {
    height: 14px;
    max-width: 100%;
    margin: -2px 10px 0px 0;
    cursor: pointer;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
    .navbar-close-sm {
        display: block;
    }
    .navbar-lg {
        display: none;
    }
    .sub-navbar {
        display: block;
    }
}