.categories {
    margin: 0 0 15px 0;
}

.categories__title {
    font-size: 14px;
    color: #606770;
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;
    margin: 0 0 15px 0;
}

.categories__button {
    margin: 0 15px 15px 0;
    padding: 10px 30px;
    background-color: transparent;
    font-size: 14px;
    color: #606770;
    font-weight: 400;
    border: 1px solid #606770;
    border-radius: 10px;
    outline: none;
    box-shadow: none;
    cursor: pointer;
}

.categories__button--active {
    background-color: #0070d0;
    color: #fff;
    border: 1px solid #0070d0;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
    .categories {
        text-align: center;
    }
    .title-categories {
        text-align: center;
    }
}