.row-scroll {
    flex-wrap: unset !important;
    overflow: scroll !important;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.row-scroll {
    flex-wrap: unset;
    overflow: scroll;
}

.row-scroll::-webkit-scrollbar {
    display: none;
}

.row-scroll__wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
}

.row-scroll__title {
    font-size: 24px;
    color: #000;
    font-weight: 600;
    line-height: 1.2;
    margin: 0 0 15px 0;
}

.row-scroll__button {
    border-radius: 10px;
    padding: 5px 10px;
    border: 1px solid #0070d0;
    background-color: transparent;
    font-size: 14px;
    color: #0070d0;
    font-weight: 400;
    margin: 0 0 15px 0;
    cursor: pointer;
}