.filter {
    margin: 0 0 15px 0;
}

.filter__input-icon {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}

.filter__icon-filter {
    height: 25px;
    width: 25px;
    cursor: pointer;
    margin: 0 0 15px 0;
}

.filter__icon-close {
    height: 22px;
    width: 22px;
    cursor: pointer;
    margin: 0 0 15px 0;
}

.filter__input {
    width: calc(100% - 40px);
    margin: 0 0 15px 0;
    padding: 15px 60px 15px 30px;
    background-color: #f2f2f2;
    background-image: url('./assets/search.svg');
    background-position: right 30px center;
    background-repeat: no-repeat;
    background-size: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #606770;
    border: none;
    border-radius: 10px;
    outline: none;
    box-shadow: none;
}

.filter__input::placeholder {
    font-size: 14px;
    color: #606770;
    font-weight: 400;
}

.filter__show-more {
    font-size: 14px;
    color: #0070d0;
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
}

.filter__select {
    width: 100%;
    margin: 0 0 15px 0;
    padding: 15px 60px 15px 30px;
    background-color: #f2f2f2;
    background-image: url('./assets/select.svg');
    background-position: right 30px center;
    background-repeat: no-repeat;
    background-size: 15px;
    font-size: 14px;
    font-weight: 400;
    color: #606770;
    border: none;
    border-radius: 10px;
    outline: none;
    box-shadow: none;
}

.filter__select {
    -moz-appearance: none;
    -webkit-appearance: none;
}

.filter__select::-ms-expand {
    display: none;
}