.content, .content-home {
    padding: 80px 0 60px 0;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
    .content {
        padding: 60px 0 30px 0;
    }
    .content-home {
        padding: 30px 0 30px 0;
    }
}