.modal-game {
    padding: 60px 30px;
}

.modal-game__icon-close {
    position: absolute;
    top: 20px;
    right: 15px;
    height: 22px;
    max-width: 100%;
    cursor: pointer;
}

.modal-game__title {
    font-size: 24px;
    color: #000;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    margin: 0 0 40px 0;
}

.modal-game__label {
    font-size: 14px;
    color: #000;
    font-weight: 600;
}

.modal-game__input {
    width: 100%;
    margin: 0 0 15px 0;
    padding: 15px 30px 15px 15px;
    background-color: #f2f2f2;
    font-size: 14px;
    font-weight: 400;
    color: #606770;
    border: none;
    border-radius: 10px;
    outline: none;
    box-shadow: none;
    margin: 10px 0 20px 0;
}

.modal-game__input::placeholder {
    font-size: 14px;
    color: #606770;
    font-weight: 400;
}

.modal-game__select {
    width: 100%;
    margin: 0 0 15px 0;
    padding: 15px 30px 15px 15px;
    background-color: #f2f2f2;
    background-image: url('./assets/select.svg');
    background-position: right 15px center;
    background-repeat: no-repeat;
    background-size: 15px;
    font-size: 14px;
    font-weight: 400;
    color: #606770;
    border: none;
    border-radius: 10px;
    outline: none;
    box-shadow: none;
    margin: 10px 0 20px 0;
}

.modal-game__select {
    -moz-appearance: none;
    -webkit-appearance: none;
}

.modal-game__select::-ms-expand {
    display: none;
}

.modal-game__button {
    width: 100%;
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    background-color: #0070d0;
    padding: 15px 30px;
    border: none;
    border-radius: 10px;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    margin: 10px 0 0 0;
}

.modal-game__note {
    font-size: 14px;
    color: #606770;
    font-weight: 400;
    line-height: 1.5;
    margin: 15px 0 0 0;
}