.footer {
    padding: 80px 0;
    background-color: #f2f2f2;
}

.footer__title {
    font-size: 16px;
    color: #000;
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;
    padding: 0 0 15px 0;
    margin: 0 0 30px 0;
    border-bottom: 1px solid #e3e3e9;
}

.footer__link {
    display: flex;
    font-size: 14px;
    color: #606770;
    font-weight: 400;
    line-height: 1.5;
    text-decoration: none;
    margin: 0 0 20px 0;
    /*  */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.footer__link:last-of-type {
    margin: 0 0 0 0;
}

.copyright {
    padding: 20px 0;
    background-color: #e3e3e9;
}

.copyright__row {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.copyright__image {
    height: 14px;
    max-width: 100%;
}

.copyright__text {
    text-align: right;
    font-size: 14px;
    color: #606770;
    font-weight: 400;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
    .footer__group {
        margin: 0 0 30px 0;
    }
    .footer {
        padding: 60px 0 30px 0;
        background-color: #f2f2f2;
    }
    .copyright__wrapper-image {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 15px;
    }
    .copyright__text {
        text-align: center;
    }
}