.intro-image {
    height: 250px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.intro-image__wrapper {
    height: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.intro-image__title {
    font-size: 35px;
    color: #fff;
    font-weight: 600;
    line-height: 1.2;
}