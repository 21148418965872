.intro-video {
    position: relative;
    height: 500px;
    width: 100%;
}

.intro-video__title-button-wrapper {
    padding: 0 15px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    text-align: center;
}

.intro-video__title {
    font-size: 35px;
    color: #fff;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    text-shadow: 2px 2px 10px rgb(0 0 0 / 20%);
    margin: 0 0 15px 0;
}

.intro-video__button {
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    background-color: transparent;
    padding: 15px 30px;
    border: 2px solid #fff;
    border-radius: 10px;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    transition: color .15s linear, background-color .15s linear, border 1s linear;
}

.intro-video__button:hover {
    color: #000;
    background-color: #fff;
    border: 2px solid #fff;
}

.intro-video__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
}

.intro-video__video {
    height: 100%;
    width: 100%;
    object-fit: cover;
}