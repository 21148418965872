.card-sidebar {
    position: relative;
    background-color: #f2f2f2;
    padding: 58px 30px 30px 30px;
    margin: 0 0 30px 0;
    border-radius: 20px;
}

.card-sidebar__title {
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 10px 20px;
    background-color: #0070d0;
    border-radius: 0 0 10px 10px;
    white-space: nowrap;
}

.card-sidebar__children a {
    font-size: 14px;
    color: #606770;
    font-weight: 400;
    line-height: 1.5;
    text-decoration: none;
    border-bottom: 1px solid rgb(227, 227, 233);
    padding: 0 0 15px 0;
    margin: 0 0 15px 0;
    /*  */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-sidebar__children a:last-of-type {
    border-bottom: none;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
}

.card-sidebar__button {
    width: 100%;
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    background-color: #0070d0;
    padding: 15px 30px;
    border: none;
    border-radius: 10px;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    margin: 30px 0 0 0;
}